import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { login, clearErrors } from "../../actions/userAction";
import CricketBallLoader from "../Layouts/loader/Loader";
import MetaData from "../Layouts/MetaData/MetaData";
import { toast } from 'react-toastify';

function Login() {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const { isAuthenticated, loading, error } = useSelector(
        (state) => state.userData
    );

    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isValidEmail, setIsValidEmail] = useState(true);

    const handleEmailChange = (event) => {
        const newEmail = event.target.value;
        setEmail(newEmail);
        setIsValidEmail(
            newEmail !== "" && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newEmail)
        );
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleShowPasswordClick = () => {
        setShowPassword(!showPassword);
    };

    const isSignInDisabled = !(email && password && isValidEmail);

    // Handle redirect after login
    useEffect(() => {
        if (error) {
            if (isAuthenticated) {
                toast.error(error);
            }
            dispatch(clearErrors());
        }

        if (isAuthenticated) {
            // Get redirect path from location state or search params
            const from = location.state?.from || location.search.split("=")[1] || "/";
            navigate(from, { replace: true });
        }
    }, [dispatch, isAuthenticated, error, navigate, location]);

    function handleLoginSubmit(e) {
        e.preventDefault();
        dispatch(login(email, password));
    }

    return (
        <>
          <MetaData title={"Login"} />
          {loading ? (
            <CricketBallLoader />
          ) : (
            <div className="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
              <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <h2 className="mt-6 text-center text-2xl sm:text-3xl font-bold text-gray-900">
                  Sign in to your account
                </h2>
                <p className="mt-2 text-center text-sm text-gray-600">
                  <Link 
                    to="/signup" 
                    className="font-medium text-blue-600 hover:text-blue-500"
                  >
                    Create an account
                  </Link>
                </p>
              </div>
    
              <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow-lg sm:rounded-lg sm:px-10">
                  <form className="space-y-6" onSubmit={handleLoginSubmit}>
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Email address
                      </label>
                      <div className="mt-1">
                        <input
                          value={email}
                          onChange={handleEmailChange}
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          required
                          placeholder="Enter your email address"
                          className={`appearance-none rounded-md relative block w-full px-3 py-2 border ${
                            !isValidEmail && email !== "" ? "border-red-500" : "border-gray-300"
                          } placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                        />
                        {!isValidEmail && email !== "" && (
                          <p className="mt-1 text-sm text-red-600">
                            Please enter a valid email address.
                          </p>
                        )}
                      </div>
                    </div>
    
                    <div>
                      <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                        Password
                      </label>
                      <div className="mt-1 relative">
                        <input
                          value={password}
                          onChange={handlePasswordChange}
                          id="password"
                          name="password"
                          type={showPassword ? "text" : "password"}
                          autoComplete="current-password"
                          required
                          placeholder="Enter your password"
                          className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                        <button
                          type="button"
                          onClick={handleShowPasswordClick}
                          className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm text-gray-500"
                        >
                          {showPassword ? "Hide" : "Show"}
                        </button>
                      </div>
                    </div>
    
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <input
                          id="remember_me"
                          name="remember_me"
                          type="checkbox"
                          className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                        />
                        <label htmlFor="remember_me" className="ml-2 block text-sm text-gray-900">
                          Remember me
                        </label>
                      </div>
                      <div className="text-sm">
                        <Link
                          to="/password/forgot"
                          className="font-medium text-blue-600 hover:text-blue-500"
                        >
                          Forgot your password?
                        </Link>
                      </div>
                    </div>
    
                    <div>
                      <button
                        type="submit"
                        disabled={isSignInDisabled}
                        className="group disabled:bg-zinc-500 relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Sign in
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </>
      );
    }
    
    export default Login;